<template>
  <b-container
    fluid
    class="mb-5"
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">

        <b-col
          cols="12"
          md="9"
        >
          <div class="form-group">
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tablePending.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <label>
              <strong>Company</strong>
            </label>
            <b-select
              v-model="tablePending.filter.company"
              :options="filterCompanies"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        ref="table"
        class="mt-2"
        :per-page="tablePending.perPage"
        :current-page="tablePending.currentPage"
        :items="tableProvider"
        :fields="tablePending.fields"
        :sort-by.sync="tablePending.sortBy"
        :sort-desc.sync="tablePending.sortDesc"
        :sort-direction="tablePending.sortDirection"
        :filter="tablePending.filter"
        :filter-included-fields="tablePending.filterOn"
        show-empty
        hover
        responsive
      >
        <template #cell(index)="data">
          {{ tablePending.currentPage * tablePending.perPage - tablePending.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell(departure)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.departure_date} ${data.item.departure_time}`) }}
          </div>
        </template>

        <template #cell(arrival)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.arrival_date} ${data.item.arrival_time}`) }}
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <b-button
            size="sm"
            variant="primary"
            @click="onProcess(row.item)"
          >
            Process
          </b-button>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tablePending.perPage"
              :options="tablePending.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tablePending.currentPage"
            :total-rows="tablePending.totalRows"
            :per-page="tablePending.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-pending-reservations-form"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      aria-hidden="false"
      modal-class="full-height"
      title="View Detail"
      @ok="onValidate"
      @show="getAvailableDriversAndVehicles"
    >
      <b-card no-body>
        <ValidationObserver
          ref="formReservation"
        >
          <form
            role="form"
            novalidate
            @submit.prevent
          >
            <b-tabs
              active-nav-item-class="font-weight-bold text-uppercase text-success"
              content-class="scrollable"
            >
              <b-tab
                active
              >
                <template #title>
                  <div class="text-md-nowrap">
                    <i class="ri-file-text-line h4" />
                    <b class="is-tab-active ml-1">
                      Details
                    </b>
                  </div>
                </template>

                <b-row class="p-2">

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="user_name">
                        <strong>Reserved By</strong>
                      </label>
                      <b-input
                        id="user_name"
                        v-model="pendingReservation.user_name"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="created_at">
                        <strong>Reserved At</strong>
                      </label>
                      <b-input
                        id="created_at"
                        v-model="pendingReservation.created_at"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="company">
                        <strong>Company</strong>
                      </label>
                      <b-input
                        id="company"
                        v-model="pendingReservation.company_name"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="organization">
                        <strong>Organization</strong>
                      </label>
                      <b-input
                        id="organization"
                        v-model="pendingReservation.organization_name"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group>
                      <label for="reservation_type">
                        <strong>Reservation Type</strong>
                      </label>
                      <b-input
                        id="reservation_type"
                        v-model="pendingReservation.reservation_type"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group>
                      <label for="passenger_count">
                        <strong>Passenger Count</strong>
                      </label>
                      <b-input
                        id="passenger_count"
                        v-model="pendingReservation.passenger_count"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="vehicle_type_name">
                        <strong>Vehicle Type</strong>
                      </label>
                      <b-input
                        id="vehicle_type_name"
                        v-model="pendingReservation.vehicle_type_name"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="departure_date">
                        <strong>Departure</strong>
                      </label>
                      <b-input
                        id="departure_date"
                        v-model="departureDateTime"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="arrival_date">
                        <strong>Arrival</strong>
                      </label>
                      <b-input
                        id="arrival_date"
                        v-model="arrivalDateTime"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-form-group>
                      <label for="purpose">
                        <strong>Purpose</strong>
                      </label>
                      <b-textarea
                        id="purpose"
                        v-model="pendingReservation.purpose"
                        type="text"
                        rows="6"
                        max-rows="6"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

              </b-tab>

              <b-tab
                v-if="pendingReservation.attachments.length > 0"
                title="Attachments"
              >
                <template #title>
                  <div class="text-md-nowrap">
                    <i class="ri-attachment-line h4" />
                    <b class="is-tab-active ml-1">
                      Attachments
                    </b>
                  </div>
                </template>

                <b-table
                  class="mt-1"
                  :fields="tableAttachments.fields"
                  :items="pendingReservation.attachments"
                  responsive
                  striped
                  hover
                >
                  <template #cell(actions)="row">
                    <div class="text-nowrap">
                      <b-button
                        size="sm"
                        class="mr-1"
                        @click="onPreviewAttachment(row.item)"
                      >
                        Preview
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-tab>

              <b-tab
                v-if="pendingReservation.reservation_type !== 'Service Driver'"
              >
                <template #title>
                  <div class="text-md-nowrap">
                    <i
                      :class="['ri-roadster-line h4', {
                        'text-danger fat fa-shake': vTabVehicles()
                      }]"
                    />
                    <b
                      :class="['is-tab-active ml-1', {
                        'text-danger': vTabVehicles()
                      }]"
                    >
                      Assign Vehicle
                    </b>
                  </div>
                </template>

                <b-row class="p-2">
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      description="Type vehicle name to search"
                    >
                      <label for="vehicle_name">
                        <strong>Vehicle</strong>
                      </label>
                      <v-select
                        id="vehicle_name"
                        v-model="selected.vehicle"
                        type="text"
                        label="name"
                        placeholder="search here"
                        :options="list.vehicles"
                        :disabled="state.busy"
                      >
                        <template #option="{ name }">
                          <strong>{{ name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Vehicle
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group>
                      <label for="license_plate">
                        <strong>License Plate</strong>
                      </label>
                      <b-input
                        id="license_plate"
                        v-model="pendingReservation.vehicle_license_plate"
                        type="text"
                        disabled
                      />
                    </b-form-group>

                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group>
                      <label for="registration_expiry">
                        <strong>Registration Expiry</strong>
                      </label>
                      <b-input
                        id="registration_expiry"
                        v-model="pendingReservation.vehicle_registration_expiry"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group>
                      <label for="seat_capacity">
                        <strong>Seat Capacity</strong>
                      </label>
                      <b-input
                        id="seat_capacity"
                        v-model="pendingReservation.vehicle_seat_capacity"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab
                v-if="pendingReservation.reservation_type !== 'Service Vehicle'"
              >
                <template #title>
                  <div class="text-md-nowrap">
                    <i
                      :class="['ri-user-forbid-line h4', {
                        'text-danger fat fa-shake': vTabDrivers()
                      }]"
                    />
                    <b
                      :class="['is-tab-active ml-1', {
                        'text-danger': vTabDrivers()
                      }]"
                    >
                      Assign Driver
                    </b>
                  </div>
                </template>

                <b-row class="p-2">
                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="driver"
                      vid="driver"
                    >
                      <b-form-group
                        description="Type driver name to search"
                      >
                        <label for="driver_name">
                          <strong>Driver</strong>
                        </label>
                        <v-select
                          id="driver_name"
                          v-model="selected.driver"
                          type="text"
                          label="name"
                          placeholder="search here"
                          :class="(errors.length > 0 ? 'is-invalid' : null)"
                          :options="list.drivers"
                          :disabled="state.busy"
                        >
                          <template #option="{ name }">
                            <strong>{{ name }}</strong>
                          </template>
                          <template #no-options="">
                            No Available Driver
                          </template>
                        </v-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group>
                      <label for="driver_license_type">
                        <strong>License Type</strong>
                      </label>
                      <b-input
                        id="driver_license_type"
                        v-model="pendingReservation.driver_license_type"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group>
                      <label for="driver_license">
                        <strong>License Number</strong>
                      </label>
                      <b-input
                        id="driver_license"
                        v-model="pendingReservation.driver_license_number"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group>
                      <label for="driver_license_expiry">
                        <strong>License Expiry</strong>
                      </label>
                      <b-input
                        id="driver_license_expiry"
                        v-model="pendingReservation.driver_license_expiry"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

              </b-tab>

              <b-tab>
                <template #title>
                  <div class="text-md-nowrap">
                    <i
                      :class="['ri-file-edit-line h4', {
                        'text-danger fat fa-shake': vTabApprovals()
                      }]"
                    />
                    <b
                      :class="['is-tab-active ml-1', {
                        'text-danger': vTabApprovals()
                      }]"
                    >
                      Approvals
                    </b>
                  </div>
                </template>

                <b-row class="p-2">
                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="remarks"
                      vid="processed_remarks"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="processed_remarks">
                          <strong>Remarks</strong>
                        </label>
                        <b-textarea
                          id="processed_remarks"
                          v-model="pendingReservation.processed_remarks"
                          rows="6"
                          max-rows="6"
                          :state="errors.length > 0 ? false : null"
                          autocomplete="off"
                          placeholder="enter remarks"
                          :disabled="state.busy"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </form>
        </ValidationObserver>
      </b-card>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          :variant="isApproved ? 'primary' : 'danger'"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ isApproved ? 'Approve' : 'Disapprove' }}
        </b-button>

        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

    <!-- modal preview image -->
    <b-modal
      id="modal-preview-image"
      size="lg"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      :title="`Preview Attachment [${file.name}]`"
    >
      <div class="d-flex justify-content-center">
        <b-img
          fluid
          :src="file.preview"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SLOReservation, SSharedList } from '@/services'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'

export default {
  name: 'VSLOPendingReservations',

  middleware: ['auth', 'logistics-officer'],

  metaInfo () {
    return {
      title: 'Pending Reservations'
    }
  },

  mixins: [formatter, url],

  data () {
    return {
      state: {
        busy: false,
        file: {
          previewing: false
        }
      },
      list: {
        approvalStatuses: [
          { value: 'Approved', text: 'Approved' },
          { value: 'Disapproved', text: 'Disapproved' }
        ],
        companies: [],
        vehicles: [],
        drivers: []
      },
      selected: {
        driver: null,
        vehicle: null
      },
      file: {
        preview: null,
        name: ''
      },
      pendingReservation: {
        id: 0,
        status: 'Disapproved',
        user: null,
        user_name: '',
        company_name: '',
        organization_name: '',
        reservation_type: '',
        departure_date: '',
        departure_time: '',
        arrival_date: '',
        arrival_time: '',
        vehicle_type: null,
        vehicle_type_name: '',
        passenger_count: 0,
        purpose: '',
        vehicle: null,
        vehicle_name: '',
        vehicle_license_plate: '',
        vehicle_registration_expiry: '',
        vehicle_seat_capacity: 0,
        driver: null,
        driver_name: '',
        driver_license_type: '',
        driver_license_number: '',
        driver_license_expiry: '',
        created_at: '',
        processed_remarks: '',
        file_path: '',
        attachments: []
      },
      tablePending: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          company: 'All',
          search: ''
        },
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', label: 'Reserved At', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'user_name', label: 'Reserved By' },
          { key: 'company_name' },
          'reservation_type',
          { key: 'vehicle_type_name', label: 'Vehicle Type' },
          'departure',
          'arrival'
        ]
      },
      tableAttachments: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      }
    }
  },

  computed: {
    filterLicenseType () {
      return [{ value: 'All', text: 'All' }].concat(this.list.licenseType)
    },

    filterCompanies () {
      return [{ text: 'All', value: 'All' }].concat(this.list.companies)
    },

    departureDateTime () {
      if (this.pendingReservation.departure_date !== '' && this.pendingReservation.departure_time !== '') {
        return this.dateTimeFormatterLong(`${this.pendingReservation.departure_date} ${this.pendingReservation.departure_time}`)
      }
      return ''
    },

    arrivalDateTime () {
      if (this.pendingReservation.arrival_date !== '' && this.pendingReservation.arrival_time !== '') {
        return this.dateTimeFormatterLong(`${this.pendingReservation.arrival_date} ${this.pendingReservation.arrival_time}`)
      }
      return ''
    },

    isApproved () {
      if (this.pendingReservation.reservation_type === 'Service Driver') {
        return this.selected.driver !== null
      }

      if (this.pendingReservation.reservation_type === 'Service Vehicle') {
        return this.selected.vehicle !== null
      }

      return this.selected.vehicle !== null && this.selected.driver !== null
    },

    confirmationMessage () {
      if (this.isApproved) {
        return 'Approve this Reservation?'
      }
      return 'Disapproved this Reservation?'
    }
  },

  watch: {
    'selected.driver' (value) {
      if (value) {
        this.pendingReservation.driver = value.id
        this.pendingReservation.driver_name = value.name
        this.pendingReservation.driver_license_type = value.license_type
        this.pendingReservation.driver_license_number = value.license_number
        this.pendingReservation.driver_license_expiry = value.license_expiry
      } else {
        this.pendingReservation.driver = null
        this.pendingReservation.driver_name = ''
        this.pendingReservation.driver_license_type = ''
        this.pendingReservation.driver_license_number = ''
        this.pendingReservation.driver_license_expiry = ''
      }
    },
    'selected.vehicle' (value) {
      if (value) {
        this.pendingReservation.vehicle = value.id
        this.pendingReservation.vehicle_name = value.name
        this.pendingReservation.vehicle_license_plate = value.license_plate
        this.pendingReservation.vehicle_registration_expiry = value.registration_expiry
        this.pendingReservation.vehicle_seat_capacity = value.seat_capacity
      } else {
        this.pendingReservation.vehicle = null
        this.pendingReservation.vehicle_name = ''
        this.pendingReservation.vehicle_license_plate = ''
        this.pendingReservation.vehicle_registration_expiry = ''
        this.pendingReservation.vehicle_seat_capacity = ''
      }
    },
    'isApproved' (value) {
      if (value) {
        this.pendingReservation.status = 'Approved'
      } else {
        this.pendingReservation.status = 'Disapproved'
      }
    }
  },

  mounted () {
    core.index()
    this.getCompanies()
  },

  methods: {

    vTabDrivers () {
      return this.$refs.formReservation && Object.keys(this.$refs.formReservation.errors).filter(
        key => ['driver'].includes(key)
      ).find(
        key => this.$refs.formReservation.errors[key]?.length > 0
      )
    },

    vTabVehicles () {
      return this.$refs.formReservation && Object.keys(this.$refs.formReservation.errors).filter(
        key => ['vehicle'].includes(key)
      ).find(
        key => this.$refs.formReservation.errors[key]?.length > 0
      )
    },

    vTabApprovals () {
      return this.$refs.formReservation && Object.keys(this.$refs.formReservation.errors).filter(
        key => ['processed_remarks'].includes(key)
      ).find(
        key => this.$refs.formReservation.errors[key]?.length > 0
      )
    },

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_company: filter.company,
        filter_text: filter.search
      })

      return await SLOReservation.get(filters).then(
        ({ data }) => {
          this.tablePending.totalRows = data.total_rows
          return data.items
        }
      ).catch(() => {
        this.tablePending.totalRows = 0
        return []
      })
    },

    async getCompanies () {
      return await SSharedList.getCompanies().then(
        ({ data }) => {
          this.list.companies = data.map(
            company => ({
              text: company.name,
              value: company.id
            })
          )
        }
      ).catch(() => {
        this.list.companies = []
      })
    },

    async getDrivers (filters = '') {
      return await SSharedList.getAvailableDrivers(filters).then(
        ({ data }) => {
          this.list.drivers = data
        }
      ).catch(() => {
        this.list.drivers = []
      })
    },

    async getVehicles (filters = '') {
      return await SSharedList.getAvailableVehicles(filters).then(
        ({ data }) => {
          this.list.vehicles = data
        }
      ).catch(() => {
        this.list.vehicles = []
      })
    },

    async getAvailableDriversAndVehicles () {
      const filters = this.objectToUrl({
        vehicle_type: this.pendingReservation.vehicle_type,
        departure_date: this.pendingReservation.departure_date,
        departure_time: this.pendingReservation.departure_time,
        arrival_date: this.pendingReservation.arrival_date,
        arrival_time: this.pendingReservation.arrival_time
      })

      this.state.busy = true

      return new Promise(
        resolve => {
          switch (this.pendingReservation.reservation_type) {
            case 'Service Vehicle': {
              this.getVehicles(filters).finally(resolve)
              break
            }
            case 'Service Driver': {
              this.getDrivers(filters).finally(resolve)
              break
            }
            default: {
              this.getVehicles(filters).finally(
                () => {
                  this.getDrivers(filters).finally(resolve)
                }
              )
              break
            }
          }
        }
      ).finally(() => {
        this.state.busy = false
      })
    },

    async onProcess (item) {
      this.pendingReservation.id = item.id
      this.pendingReservation.user_name = item.user_name
      this.pendingReservation.company_name = item.company_name
      this.pendingReservation.organization_name = item.organization_name
      this.pendingReservation.reservation_type = item.reservation_type
      this.pendingReservation.departure_date = item.departure_date
      this.pendingReservation.departure_time = item.departure_time
      this.pendingReservation.arrival_date = item.arrival_date
      this.pendingReservation.arrival_time = item.arrival_time
      this.pendingReservation.vehicle_type = item.vehicle_type_id
      this.pendingReservation.vehicle_type_name = item.vehicle_type_name
      this.pendingReservation.passenger_count = item.passenger_count
      this.pendingReservation.purpose = item.purpose
      this.pendingReservation.attachments = item.attachments
      this.pendingReservation.created_at = this.dateTimeFormatter(item.created_at)
      this.pendingReservation.processed_remarks = ''
      this.pendingReservation.file_path = item.file_path
      this.selected.driver = null
      this.selected.vehicle = null
      this.$bvModal.show('modal-pending-reservations-form')
    },

    async onValidate (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formReservation.validate().then(
        async areAllFieldsValid => {
          if (areAllFieldsValid) {
            this.$swal.fire({
              icon: 'question',
              title: 'Confirmation',
              text: this.confirmationMessage,
              confirmButtonColor: this.isApproved ? '#06C270' : '#FF2929',
              confirmButtonText: this.isApproved ? 'Approve' : 'Disapprove',
              cancelButtonColor: '#737373',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: () => {
                return this.onPut()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk('Oops! Your inputs seem to be incorrect or incomplete, please check and try again.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    async onPut () {
      this.state.busy = true
      return new Promise(
        resolve => {
          SLOReservation.put({
            id: this.pendingReservation.id,
            status: this.pendingReservation.status,
            vehicle: this.pendingReservation.vehicle,
            driver: this.pendingReservation.driver,
            processed_remarks: this.pendingReservation.processed_remarks
          }).then(
            ({ data }) => {
              this.$bvModal.hide('modal-pending-reservations-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                this.$refs.table.refresh()
              })
            }
          ).catch(error => {
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.formReservation.setErrors(error.message)
              }
            }
          }).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    },

    onPreviewAttachment (item) {
      this.file.name = item.file_original_name
      this.file.preview = this.getImageUrl(`${this.pendingReservation.file_path}/${item.file_hash_name}`)
      this.$bvModal.show('modal-preview-image')
    }
  }
}
</script>
